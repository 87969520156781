.brand{
  a{
    background-color: #f1f1f1;
    box-shadow: 0 3px 3px -2px rgba(0,0,0,0.2),
                0 3px 4px 0 rgba(0,0,0,0.14),
                0 1px 8px 0 rgba(0,0,0,0.12);
    padding: 3px 5px;
    border-radius: 10px;
    color: #333;
    transition: all .2s ease-in-out;
  }
  h1{
    font-size: 2rem;
  }
  img{
    position: relative;
    margin: 0 2px;
    transform:  translateY(  4px);
  }
}
@media only screen and (max-width: 350px) {
  .brand{
    display: none;
  }
}
