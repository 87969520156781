.Attribution{
  background-color: #333;
  display: flex;
  flex-direction: column;
  text-align: center;
  .Attribution-div{
    flex-grow: 1;
    padding: 10px;
    margin: 5px;
    background-color: #f1f1f1;
    border-radius: 3px;
  }
  h3{
    text-transform: uppercase;
    padding: 10px;
    color: #fff;
    font-size: 1.5rem;
  }
  a{
    color: #07689f;
    text-decoration: none;
  }
}

