#main {
  font-family: Consolas, sans-serif;
  background: #794141;
  background: linear-gradient(110deg, #eb4559 50%, #f78259 50%);
  height: 100vh;
  text-align: center;
  display: flex;
  flex-flow: column wrap;
  .nav-item {
    height: 10vh;
    display: flex;
    font-size: 1rem;
    .navbar-brand {
      font-family: 'Roboto', sans-serif;
      color: #f8eeee;
      font-size: 2.4rem;
      margin-left: 10px;
      margin-top: 10px;
    }
  }
  .main-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    height: 90vh;
    .main-row-img{
      flex-basis: 50%;
      flex-grow: 1;
      .head-phone-img {
        width: 40%;
        
      }
    }
    .main-row-text{
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      flex-basis: 50%;
      flex-grow: 1;
      h1 {
        font-family: "Bungee", cursive;
        font-size: 2rem;
       
      }
      p {
        color: #f8eeee;
        font-size: 1.2rem;
      }
      .btn{
        // font-family: Consolas, sans-serif;
        // letter-spacing: 2px;
        // border: none;
        // background-color: #282c34;
        // color: #fff;
        // font-size: 1.1rem;
        // text-transform: uppercase;
        // margin: 10px 0;
        // padding: 15px 20px;
        // border-radius: 5px;
        font-size: 2rem;
        color: white;
        margin-top: 10px;
        background-color: black;
        border-radius: 50px;
        padding: 15px 20px;
        height: 60px;
        width: 65px;

      }
      .btn:hover{
        opacity: 0.9;
        cursor: pointer;
      }
    }
  }
}
