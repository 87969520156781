.AddMusic{
  height: 80vh;
  display: flex;
  overflow: auto;
  .add-music-sub-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
    input,select,button{
      width: 100%;
      padding: 10px;
      border: none;
      box-shadow: 1px 2px #b0b0b0;
      outline: none;
    }
    .d1,.d2{
      margin: 10px;
    }
    .d1{
      display: flex;
      flex-direction: column;
      button{
        margin-bottom: 15px;
      }
    }
    .d2{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      .preview{
        user-select: none;
        padding: 10px;
        color: #616060;
        margin: 10px 0;
        background-color: #fff;
        box-shadow: 2px 3px #b0b0b0;
      }
      input{
        margin-bottom: 15px;
      }
    }

  }
}
@media only screen and (max-width: 970px) {
  .AddMusic {
    height: 70vh;
  }
}